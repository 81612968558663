.UnauthenticatedApp {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
  --toastify-color-light: rgb(255, 255, 255);
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 520px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 32px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  --toastify-z-index: 999999999;
}

.Toastify__toast {
  padding: 0;
  box-shadow: none;
  margin: 8px 12px 8px 0px;
}

.Toastify__toast-body {
  padding: 0;
  font-size: 12px;
}

.Toastify__toast-icon {
  margin-left: 12px;
  width: 16px;

}

.Toastify__spinner {
  width: 16px;
  height: 16px;
}